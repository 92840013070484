html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.185),
    rgba(12, 8, 24, 0.377)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.137),
    rgba(12, 10, 22, 0.151)
  );

  --imp-text-color: #139bcc;
  font-size: 16px;
}

.heading {
  padding: 10px 20px 15px 20px !important; /* top right bottom left */
  font-size: 24px; /* Your desired font size */
}


/* Add margin to the top of the body content to separate it from the navbar */
body {
  margin-top: 4rem; /* Adjust the margin as needed for spacing */
  margin-bottom: 4rem;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}




._0G0_70KK893NLapnXPp.gBEYucbjVlskZBiWm_0K {
  /* create a clipping region that covers the whole iframe except the bottom 72px, then show the bottom 51px */
  clip-path: inset(0 0 calc(100% - 72px) 0), inset(auto auto 51px auto);
}


.css-1dbztwz {
  display: none;
}


/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #13051000;
  background-image: url(./Assets/front.png);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}










/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b279d8f5;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}






/* --------- */
/* Navbar Section  */
/* --------- */
@supports (-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px)) {
  .sticky {
    background-color: rgba(27, 26, 46, 0.6);
    -webkit-backdrop-filter: blur(25px) !important;
    backdrop-filter: blur(15px) !important;
  }
}

.sticky {
  background-color: rgba(27, 26, 46, 0.6);
  transition: all 0.3s ease-out !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem; /* Top and bottom padding */
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative;
  background-color: transparent;
  border-color: transparent;
}

.navbar-toggler span {
  display: block;
  background-color: #139bcc;
  height: 4px;
  width: 27px;
  margin-top: 5px;
  margin-bottom: 5px;
  transform: rotate(0deg);
  opacity: 1;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(-135deg);
  opacity: 0.9;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem; /* Increase the padding for nav bar */
    font-size: 1.4rem;
    background-color: rgba(27, 26, 46, 0.6);
  }

  .navbar-nav .nav-item a::after {
    display: none;
  }

  
}

.navbar-brand {
  color: rgb(250, 250, 250);
}

/* Logo Styling */
.logo {
  width: 5rem;
  height: auto;
  max-width: auto;
  display: block;
}

.navbar-nav .nav-link {
  font-size: 1.25rem;
  color: white !important;
  padding: 1rem 1rem 1rem 1rem; /* Increase the padding for nav links */
}

.nav-link {
  padding: 1rem 1rem; /* Increase the padding for nav links */
}

@media (max-width: 767px) {
  .nav-link {
    padding: 1rem; /* Increase the padding for nav links */
  }

  .logo {
    width: 5rem;
    height: auto;
    max-width: auto;
    display: block;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
  /* margin-left: 0px; */
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
  display: inline-block; /* Add this line */
}

.navbar-nav .nav-item a::after {
  content: "";
  position: absolute; /* Change this from relative to absolute */
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #139bcc;
  bottom: -1px; /* Adjust this value as needed */
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}










/* --------- */
/* Home section */
/* --------- */
.lion-wave {
  animation-name: lion-wave-animation;
  animation-duration: 2s; /* Adjust the duration as needed */
  animation-iteration-count: infinite;
  transform-origin: 50% 50%; /* Center the wave animation */
  display: inline-block;
}

@keyframes lion-wave-animation {
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px) rotate(-5deg);
  }
  50% {
    transform: translateY(0) rotate(0deg);
  }
  75% {
    transform: translateY(5px) rotate(5deg);
  }
}


#about{
  padding: 1rem 1rem 1rem 1rem !important;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  text-align: center; /* Center text */
  display: flex;
}

#home > div.home-content.container{
  padding: 1rem 1rem 1rem 1rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#home > div.home-content.container > div{
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  text-align: center; /* Center text */
}

#home > div.home-content.container > div > div.home-header.col-md-7{
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#home > div.home-content.container > div > div.col-md-5{
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#about > div{
  justify-content: center;
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 1rem 0rem 0rem 0rem !important;

}

#about > div > div:nth-child(1){
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#about > div > div:nth-child(1) > div{
  padding: 6rem 1rem 2rem 1rem !important;
  margin: 0rem 0rem 0rem 0rem !important;
}

#about > div:nth-child(1){
  padding: 0rem 0rem 0rem 0rem !important;
  margin: 0rem 2rem 0rem 2rem !important;

}

#about > div:nth-child(1) > div{
  color: rgb(255, 255, 255) !important;
  font-size: 1.4em !important;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.home-about-description h1 {
  margin-bottom: 50px;
}


@keyframes backgroundImageAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}


.home-section {
  position: relative;
  z-index: -1;
  background-image: var(--image-gradient), url(./Assets/front.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  animation: backgroundImageAnimation 5s ease-in-out; /* Adjust duration and other properties as needed */
  animation-fill-mode: forwards; /* Keeps the styles of the last keyframe when animation ends */
}

@media (max-width: 767px) {
  .home-section {
    background-size: cover; /* or use 'cover' or 'auto' depending on your preference */
    background-position: center right 15%;
  }
}


.home-content {
  color: whitesmoke;
}

.heading {
  font-size: 1.5rem !important;
}

.image-container {
  text-align: center;
}

.image {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.heading-name {
  white-space: nowrap;
  font-size: calc(1.2rem + 3vw) !important;
}

.main-name {
  color: #139bcc;
  
}

.Typewriter__wrapper {
  color: #139bcc !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.Typewriter__cursor {
  display: inline-block !important;
  color: #139bcc !important;

  padding-top: 2rem !important;
}

.Typewriter__wrapper,
.Typewriter__cursor {
  font-size: calc(1rem + 1vw);
  line-height: normal;
}


@media (max-width: 767px) {
  .Typewriter__wrapper {
    color: #139bcc !important;
    font-weight: 500 !important;
    line-height: normal !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
    
  }
  
  .Typewriter__cursor {
    display: inline-block !important;
    color: #139bcc !important;
  }
  
  .Typewriter__wrapper,
  .Typewriter__cursor {
    font-size: calc(1rem + 1vw);
    line-height: normal;
  }

  .home-about-section {
    padding-bottom: 50px !important;
    padding-top: 50px !important;
  }

  .home-about-description {
    padding-top: 50px !important;
    padding-bottom: 20px !important;
    text-align: center !important;
  }

  .home-about-body {
    padding-top: 30px;
    text-align: center;
  }
}

.myAvtar {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-end; /* Align to the bottom */
}


@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

@media (max-width: 767px) {
  .home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
  }
}

.home-about-social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 50px !important;
  height: 50px !important;
  text-align: center !important;
  font-size: 30px !important;
  line-height: 45px !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  background: rgba(255, 255, 255, 0.938) !important;
  justify-content: center;


}

@media (max-width: 767px) {
  .home-social-icons {
        position: relative !important;
        display: inline-block !important;
        width: 40px !important;
        height: 40px !important;
        font-size: 30px !important;
        text-align: center !important;
        line-height: 45px !important;
        background: rgba(255, 255, 255, 0.972) !important;border-radius: 50% !important;
        transition: 0.5s !important;
        justify-content: center;
        padding-inline-start: 0 !important;
  }
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}



.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}













/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative;
  padding: 150px 0 30px;
  background-image: var(--section-background-color);
  color: white;
  display: flex;
}

#scroll > div.about-section.container-fluid > div.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#scroll > div.about-section.container-fluid > div.container > div:nth-child(1){
  align-items: center;
  justify-content: center;
  width: 100%;
}

#scroll > div.about-section.container-fluid > div.container > div:nth-child(2){
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Tech Icons */
.tech-icons {
  font-size: 4.75em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  position: relative;
}

/* Responsive Styling for Tech Icons */
@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

/* Hover Effect on Tech Icons */
.tech-icons:hover {
  transform: scale(1.05) !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}

/* Display Tech Name on Hover */
.tech-icons:hover::before {
  content: attr(techname);
  position: absolute;
  background: rgba(0, 0, 0, 0);
  color: #fff;
  font-size: 15px;
  padding: 5px;
  border-radius: 4px;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

}

/* Quote Card Styling */
.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

/* Styling for Activities List */
.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

/* Responsive Styling for About Image */
@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}





























/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 125px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}












/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  width: auto !important;
}

.blog-card-view-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto; /* Adjust as needed */
  height: auto; /* Adjust as needed */
  padding: 20px; /* Adjust as needed */
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}









/*Blog Post */
.blog-post-card {
  padding: 0rem 0rem 1.5rem 0rem !important;
  height: auto !important;
  width: auto !important; /* Adjust as needed */
}

.blog-post-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  display: flex !important; /* Makes the container a flex container */
  flex-direction: column !important; /* Stacks the items vertically */
  align-items: center !important; /* Centers the items vertically */
  justify-content: center !important; /* Centers the items horizontally */
  width: auto !important; /* Adjust as needed */
  height: auto !important; /* Adjust as needed */
  margin: 8rem 2rem 3rem 2rem;
  padding: 1rem 3rem 3rem 3rem;
}

.blog-post-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.blog-post-content {
  width: auto; /* Adjust as needed */
  height: auto; /* Adjust as needed */
}

.blog-post-data {
  width: auto; /* Adjust as needed */
  height: auto; /* Adjust as needed */
}



.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

#scroll > div.blogs-section.container-fluid {
  padding: 150px 12px 30px 12px !important;
}

#scroll > div.blog-post-container.container-fluid > div.blog-post-card-view > div > div > div > div > p:nth-child(1) {
  margin: 0;
}

#scroll > div.blog-post-container.container-fluid > div.blog-post-card-view > div > div > div > div > p:nth-child(2) {
  margin: 0;
}

#scroll > div.blog-post-container.container-fluid > div.blog-post-card-view > div > div > div > div > div {
  margin-top: 1rem;
  font-size: calc(1rem + 0.4vw);
  text-align: justify;
}














/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  padding: 10px 0 8px 0;
  text-align: center;
  color: white;
}

.footer-copywright, .footer-body {
  text-align: center;
}

@media (max-width: 767px) {
  .footer-copywright, .footer-body {
    text-align: center;
  }
}

.footer h3 {
  font-size: 1em;
  color: white;
  margin: 0.5em 0;
}

.footer-icons {
  margin: 0.5em 0;
  padding: 0;
}

.blockquote-footer {
  color: #a588c0;
}



















#decoration {
  position: absolute;
  left: -59px;
  display: grid;
  gap: 3px;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(10, 1fr);
  place-items: stretch;
  height: 90px;
  width: 500px;
  transform: rotate(-55deg);
  transition: opacity 0.5s ease-in-out;
  z-index: 1;
}
.pill {
  display: block;
  background: linear-gradient(to left, var(--imp-text-color), transparent);
  opacity: 0.75;
  border-radius: 1000px;
}


@keyframes pill-float {
  from {
    transform: translateX(-250px);
  }

  to {
    transform: translateX(250px);
  }
}

#pill-1 {
  grid-column: 4 / span 8;
  grid-row: 5 / span 3;
  animation: 20s infinite pill-float linear;
  animation-delay: 0.2s;
}

#pill-2 {
  grid-column: 10 / span 4;
  grid-row: 6 / span 1;
  animation: 30s infinite pill-float linear;
}

#pill-3 {
  grid-column: 8 / span 3;
  grid-row: 3 / span 1;
  animation: 35s infinite pill-float linear;
  animation-delay: 0.1s;
}

#pill-4 {
  grid-column: 4 / span 3;
  grid-row: 3 / span 1;
  animation: 25s infinite pill-float linear;
  animation-delay: 0.3s;
}

#pill-5 {
  grid-column: 6 / span 4;
  grid-row: 9 / span 1;
  animation: 40s infinite pill-float linear;
  animation-delay: 0.4s;
}

#pill-6 {
  grid-column: 11 / span 1;
  grid-row: 9 / span 1;
  animation: 30s infinite pill-float linear;
  animation-delay: 0.5s;
}

#pill-7 {
  grid-column: 13 / span 2;
  grid-row: 10 / span 1;
  animation: 25s infinite pill-float linear;
  animation-delay: 0.6s;
}

#pill-8 {
  grid-column: 13 / span 3;
  grid-row: 8 / span 1;
  animation: 20s infinite pill-float linear;
  animation-delay: 0.7s;
}

#pill-9 {
  grid-column: 14 / span 3;
  grid-row: 4 / span 1;
  animation: 40s infinite pill-float linear;
  animation-delay: 0.8s;
}

#pill-10 {
  grid-column: 12 / span 1;
  grid-row: 4 / span 1;
  animation: 35s infinite pill-float linear;
  animation-delay: 0.4s;
}
